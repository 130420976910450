import { IExtendObj } from '@/types'
import { post, get } from '@/utils/request'
import { dataType } from 'element-plus/es/components/table-v2/src/common'

export interface IStore {
  cusStoreId: number
  storeName: string
  storeType: number
  /**
   * 1.仅上架批发商品
   * 2.仅上架代发商品
   * 3.批发与代发商品分开上架
   * 4.批发与代发商品同时上架
   */
  shelfConfig: string
  disabled?: boolean
  isUnauthorizedOrder?: string // 未认证下单
  isSignOrder?: string // 未签署下单
  isUnauthorizedPrice?: string // 未认证查看价格
  isSignPrice?: string // 未签署查看价格
}

export interface ILoginResult {
  deptId: string
  deptName: string
  firstLogin: number
  id: number
  lastLoginTime: string
  updatePass: boolean
  name: string
  parentName?: string
  parentUserId?: number
  nickName: string
  phone: string
  token: string
  userId: number
  userName: string
  avatarUrl?: string
  settleType: string
  causeDepartmentId: number
  customerId: number
  storeIdList: number[]
  isEmptyPass: number
  customerCode: string
  customerSource?: string // 账号来源 1:客户注册2-后台新增
  customerType?: string // 账号类型 1:个人2:企业
  isConfirm: boolean // 登录信息返回是否确认
  type: number // 1: 内部用户 2：客户
}

const gate = '/api/sc'

export function login(name: string, password: string) {
  return post<ILoginResult>(
    `${gate}/user/v1/login`,
    { userName: name, password: password, type: 1 },
    { isUnToken: true }
  )
}

export function phoneLogin(phone: string, smsCode: string) {
  return post<ILoginResult>(
    `${gate}/user/v1/login`,
    { phone, smsCode, type: 1 },
    { isUnToken: true }
  )
}
export function phoneLogin2(phone: string, smsCode: string) {
  return post<ILoginResult>(`${gate}/user/v1/login2`, { phone, smsCode }, { isUnToken: true })
}

export function getSignature(data: any) {
  return post<any>(`${gate}/file/v1/get_policy`, data)
}

// 修改登录密码
export function updatePassword(data: any) {
  return post<any>(`${gate}/user/v1/update_self_password`, data)
}

// 获取公告
export function selectMaintenances() {
  return post<any>(`${gate}/notice/v1/select_maintenances`, {}, { isUnToken: true })
}

// 获取任务管理
export function getTaskList(data: any) {
  return post<any>(`${gate}/task/v1/select_task`, data)
}

// 获取短信验证码(登录时候用到)
export function sendSms(phone: string) {
  return post<void>(`${gate}/user/v1/send_sms?phone=${phone}`, {})
}

// 获取短信验证码(注册时候、无token用到)
export function regSendSms(phone: string) {
  return post<void>(`${gate}/user/v1/send_register_sms?phone=${phone}`, {}, { isUnToken: true })
}
// 获取短信验证码(实名重复)
export function reserveSendSms(phone: string) {
  return post<void>(`${gate}/user/v1/send_user_reserve_sms?phone=${phone}`, {})
}
// 获取短信验证码(实名重复绑定新手机)
export function reserveNewBindSendSms(phone: string) {
  return post<void>(`${gate}/user/v1/send_user_reserve_bind_new_sms?phone=${phone}`, {})
}
/**
 * 获取自定义模板配置
 * @returns Promise
 */
export function parseExcelTitle(data: any) {
  return post<any>(`${gate}/template/v1/parse_excel_title`, data)
}

/**
 * 保存用户自定义配置模板
 * @param {*} data
 * @returns
 */
export function saveCustomImportTemplate(data: any) {
  return post<any>(`${gate}/template/v1/save_custom_import_template`, data)
}

/**
 * 修改用户自定义配置模板
 * @param {*} data
 * @returns
 */
export function updateCustomImportTemplate(data: any) {
  return post<any>(`${gate}/template/v1/update`, data)
}

/**
 * 保存用户自定义配置模板
 * @param {*} data
 * @returns
 */
export function queryTemplateById(data: any) {
  return post<any>(`${gate}/dynamic_common/v1/query_form_data_by_id`, data)
}

/**
 * 根据idList查找模板
 * @param {*} data
 * @returns
 */
export function selectTemplate(data: any) {
  return post<any>(`${gate}/template/v1/select_template`, data)
}

/**
 * 删除用户自定义配置模板
 * @param {*} data
 * @returns
 */
export function deleteTemplateById(data: any) {
  return post<any>(`${gate}/dynamic_common/v1/delete_dynamic_form_data`, data)
}

export function getTaskById(data: any = {}) {
  return post<any>(`${gate}/task/v1/getById?id=${data.id}`, data)
}

interface IDictValue {
  name: string
  value: string
  label: string
}

export interface IDictResult {
  dictCode: string
  dictName: string
  dictValueList: IDictValue[]
}

/**
 * 获取字典
 * @param {*} data
 * @returns
 */
export function getDictValue(dictCode: string) {
  return post<IDictResult[]>(
    `${gate}/dict/v1/select_dict_by_code_list?dictCodeList=${dictCode}`,
    {},
    { isUnToken: true }
  )
}

/**
 * 修改头像
 * @param {*} data
 * @returns
 */
export function updateAvatar(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/user/v1/update_mini_program_user`, data)
}

/**
 * 注册接口
 * @param {*} data
 * @returns
 */
export function registerUser(data: IExtendObj) {
  return post<ILoginResult>(`${gate}/user/v1/registerCustomerUser`, data, { isUnToken: true })
}

/**
 * 校验账户名/手机号是否重复
 * @param {*} data
 * @returns
 */
export function checkUser(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/check_user_regist`, data, { isUnToken: true })
}

/**
 * 修改手机号
 * @param {*} data
 * @returns
 */
export function updateModile(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/update_mini_program_user`, data)
}

/**
 * 忘记密码获取手机验证码
 * @param {*} data
 * @returns
 */
export function resetModileSms(data: string) {
  return post<any>(`${gate}/user/v1/send_forget_pass_sms?phone=${data}`, {}, { isUnToken: true })
}

/**
 * 重置密码
 * @param {*} data
 * @returns
 */
export function resetPwd(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/forget_reset_pass`, data, { isUnToken: true })
}

/**
 * 获取账号/手机号是否存在
 * @param {*} data
 * @returns
 */
export function userOrPhoneExist(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/select_user_info`, data, {
    isUnToken: true,
    isUnErrorMessage: true
  })
}

/**
 * ;确认信息
 * @param {*} data
 * @returns
 */
export function confirmUSer(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/confirm_user`, data)
}

/**
 * 选择店铺确认接口
 */
export function changeUserStore(data: { cusStoreId: number }) {
  return post<any>(`${gate}/user/v1/choose_store`, data)
}

/**
 * 查询客户授权店铺列表
 */
export function getUserStore() {
  return post<any>(`${gate}/user/v1/select_user_store_list`, {})
}

/**
 * 获取菜单
 */
export function selectMenuTree() {
  return get<any>(`${gate}/menu/v1/select_menu_tree`, {})
}

/**
 * 获取表及子表字段
 */
export function selectGridColumn(tableName: string) {
  return post<any>(`${gate}/dynamic_form/v1/select_grid_column?tableName=${tableName}`, {})
}

/**
 * 动态表单通用查询列表接口
 */
export function queryGridDynamicFormData(data: IExtendObj) {
  return post<any>(`${gate}/dynamic_common/v1/query_grid_dynamic_form_data`, data)
}

/**
 * 动态表单通用保存接口
 */
export function saveDynamicFormData(data: IExtendObj) {
  return post<any>(`${gate}/dynamic_common/v1/save_dynamic_form_data`, data)
}

/**
 * 动态表单通用删除接口
 */
export function deleteDynamicFormData(data: IExtendObj) {
  return post<any>(`${gate}/dynamic_common/v1/delete_dynamic_form_data`, data)
}

/**
 * 脱敏记录
 */
export function saveTrackEvent(data: IExtendObj) {
  return post<any>(`${gate}/track/v1/save_track_event`, data)
}

/**
 * 埋点
 */
export function saveTrackFromLog(data: IExtendObj) {
  return post<any>(`${gate}/track/v1/save_fron_log`, data)
}

/**
 * 系统通知（站内信）分页
 */
export function selectStationNoticePage(data: IExtendObj) {
  return post<any>(`${gate}/notice/v1/select_station_notice_page`, data)
}

/**
 * 查询系统通知（站内信）未读数
 */
export function queryStationNoticeUnreadSum(data: IExtendObj) {
  return post<any>(`${gate}/notice/v1/query_station_notice_unread_sum`, data)
}

/**
 * 系统消息（站内信）设置已读
 */
export function readStationNotice(data: IExtendObj) {
  return post<any>(`${gate}/notice/v1/read_station_notice`, data)
}

/**
 * 系统消息（站内信）查看详情
 */
export function selectStationNoticeDetail(data: IExtendObj) {
  return post<any>(`${gate}/notice/v1/station_notice_detail`, data)
}
/**
 * 查询子账号
 * @param data
 * @returns
 */
export function selectConditionUser(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/select_condition_user`, data)
}
/**
 * 创建子账号
 * @param data
 * @returns
 */
export function createSubAccount(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/create_sub_account`, data)
}
/**
 * 更改子账号状态
 * @param data
 * @returns
 */
export function batchUpdateStatusById(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/batch_update_status_by_id`, data)
}
/**
 * 查询子账号角色（系统角色&用户自定义角色
 * @param data
 * @returns
 */
export function selectSubAccountRole(data: IExtendObj) {
  return post<any>(`${gate}/role/v1/select_sub_account_role`, data)
}
/**
 * 查询子账号权限
 * @param data
 * @returns
 */
export function selectMallMiniFunction(userId?: number) {
  return post<any>(`${gate}/function_permission/v1/select_mall_mini_function?userId=${userId}`, {})
}

/**
 * 保存角色权限
 * @param data
 * @returns
 */
export function saveCustomRolePermission(data: IExtendObj) {
  return post<any>(`${gate}/function_permission/v1/save_custom_role_permission`, data)
}
/**
 * 查询角色权限
 * @param data
 * @returns
 */
export function selectMallMiniFunctionRole(data: IExtendObj) {
  return post<any>(`${gate}/function_permission/v1/select_mall_mini_function_role`, data)
}
/**
 * 删除子账号角色
 * @param data
 * @returns
 */
export function deleteUserRole(data: IExtendObj) {
  return post<any>(`${gate}/role/v1/delete_user_role`, data)
}
/**
 * 查询特殊权限按钮接口
 * @param data
 * @returns
 */
export function selectSpecialAction(data: IExtendObj) {
  return post<any>(`${gate}/function_permission/v1/select_special_action`, data)
}

/**
 * 查询用户信息
 * @param data
 * @returns
 */
export function selectUserInfo(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/selectUserInfo`, data)
}

/**
 * 校验账号
 * @param data
 * @returns
 */
export function reserve(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/reserve`, data)
}
/**
 * 确认保留账号
 * @param data
 * @returns
 */
export function batchDisUser(data: IExtendObj) {
  return post<any>(`${gate}/user/v1/batch_dis_user`, data)
}
